import { LoadingManager, Texture, TextureLoader } from 'three';
import { resources } from '../Assets';

export class ResourceLoader {
  private manager: LoadingManager
  private callback: () => void;
  private textureLoader!: InstanceType<typeof TextureLoader>;
  public textures: Record<string, Texture>;

  constructor(callback: () => void) {
    this.callback = callback // Callback when finished loading resources
    this.textures = {} // Map objects
    this.setLoadingManager()
    this.loadResources()
  }
  /**
   * LoadingManager State
   */
  private setLoadingManager() {

    this.manager = new LoadingManager()
    // Start Loading
    this.manager.onStart = () => {
      // console.log('Loading files.')
    }
    // Loading complete
    this.manager.onLoad = () => {
      this.callback()
    }
    // In Progress
    this.manager.onProgress = (url) => {
      // console.log(`Loading: ${url}`)
    }
    // Failed 
    this.manager.onError = url => {
      // console.log('Failed to load: ' + url)
    }
  }
  /**
   * .Map & load images
   */
  private async loadResources(): Promise<void> {
    this.textureLoader = new TextureLoader(this.manager);
    resources.textures.forEach((item)  => {
      this.textureLoader.load(item.url, (texture) => {
        this.textures[item.name] = texture
      })
    })
  }
}