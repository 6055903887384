import { createSlice } from '@reduxjs/toolkit';

export const AuthModalSlice = createSlice({
    name: 'authModal',
    initialState: {
      visible: false,
    },
    reducers: {
      enableAuthModal: (state) => {
        state.visible = true;
      },
      disableAuthModal: (state) => {
        state.visible = false;
      },
    },
})

export const { enableAuthModal, disableAuthModal } = AuthModalSlice.actions

export const authModalVisibleStatus = (state: { authModal: { visible: any; }; }) => state.authModal.visible;
  
export default AuthModalSlice.reducer