import { Color, PerspectiveCamera, Scene, WebGLRenderer } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export class ThreeCanvas {
    public mainDOM: HTMLElement;
    public scene: Scene;
    public camera: PerspectiveCamera;
    public renderer: WebGLRenderer;
    public controls: OrbitControls;
    
    constructor(mainDOM: HTMLElement) {
        this.mainDOM = mainDOM;
        this.createScenes();
        this.createControls();
    }

    createScenes() {
        this.scene = new Scene();
        this.scene.background = new Color(0x000000);
        this.camera = new PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 100000 );
        this.camera.position.set(30, 2, 0)
        this.renderer = new WebGLRenderer({ alpha: true, antialias: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.mainDOM.appendChild(this.renderer.domElement);
    }

    createControls() {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.autoRotateSpeed = 3
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.05;
        this.controls.enableZoom = true;
        this.controls.minDistance = 13;
        this.controls.maxDistance = 300;
        this.controls.enablePan = false;
    }
}