
interface ITextures {
    name: string
    url: string
  }
  const textures: ITextures[] = [
    {
      name: "skybox",
      url: "https://firebasestorage.googleapis.com/v0/b/calypso-fbm-app.appspot.com/o/resources%2FThreeJS%2FPlanets%2FEarth%2Fskybox.jpg?alt=media&token=08d98cc1-b36d-4d27-a7d4-de2e39a15673"
    },
    {
      name: "surface",
      url: "https://firebasestorage.googleapis.com/v0/b/calypso-fbm-app.appspot.com/o/resources%2FThreeJS%2FPlanets%2FEarth%2Fsurface.jpg?alt=media&token=f4aad6a0-2620-4dfe-84df-f2668bca2638"
    },
    {
      name: "atmosphere",
      url: "https://firebasestorage.googleapis.com/v0/b/calypso-fbm-app.appspot.com/o/resources%2FThreeJS%2FPlanets%2FEarth%2Fatmosphere.png?alt=media&token=b8c34a50-ba08-4c7a-b21a-4bf825ab39a6"
    },
    {
      name: "glow",
      url: "https://firebasestorage.googleapis.com/v0/b/calypso-fbm-app.appspot.com/o/resources%2FThreeJS%2FPlanets%2FEarth%2Fglow.png?alt=media&token=300c1a8d-b479-4af4-99f0-0a99942950a2"
    },
    {
      name: "gradient",
      url: "https://firebasestorage.googleapis.com/v0/b/calypso-fbm-app.appspot.com/o/resources%2FThreeJS%2FPlanets%2FEarth%2Fgradient.png?alt=media&token=41991e4a-77c4-4c92-bcf9-027eb3e077f1"
    },
    {
      name: "moon",
      url: "https://firebasestorage.googleapis.com/v0/b/calypso-fbm-app.appspot.com/o/resources%2FThreeJS%2FPlanets%2FMoon%2Flroc_color_poles_1k.jpg?alt=media&token=1d8a9120-9dbe-46f2-bfef-84feae44e09b"
    },
    {
      name: "clouds",
      url: "https://firebasestorage.googleapis.com/v0/b/calypso-fbm-app.appspot.com/o/resources%2FThreeJS%2FPlanets%2FEarth%2F4k-earth-clouds.png?alt=media&token=a0a715d8-5ecd-4f42-ae4a-c533a07e6283"
    }
  ]
  

  export interface IResources {
    textures: ITextures[],
  }

  const resources: IResources = {
    textures
  }

  export {
    resources
  }