// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
    ArcCurve, BufferAttribute, BufferGeometry,
    Color, Line, LineBasicMaterial, Points, PointsMaterial,
    Quaternion, Vector3
} from 'three';
import { lon2xyz } from './common';

/*
 * Draw an arc flyline
 * The meaning of 5 parameters: (flying line, arc track radius, start angle, end angle)
 */
function createFlyLine(radius, startAngle, endAngle, color) {
    const geometry = new BufferGeometry(); // Declare a geometry object BufferGeometry 
    const arc = new ArcCurve(0, 0, radius, startAngle, endAngle, false); //   ArcCurve creates arc curves

    // getSpacedPoints() is a method of the base class Curve that returns a Vector2 object as an Array<elements>
    const pointsArr = arc.getSpacedPoints(100); // Number of segments 80, returns 81 vertices
    geometry.setFromPoints(pointsArr); //  The setFromPoints() method extracts data from pointsArr and changes the vertex attribute vertices of the geometry
    // Each vertex corresponds to a percentage data attributes.percent is used to control the rendering size of the point
    const percentArr = []; // attributes.percent data
    for (let i = 0; i < pointsArr.length; i++) {
        percentArr.push(i / pointsArr.length);
    }
    const percentAttribue = new BufferAttribute(
        new Float32Array(percentArr),
        1
    );
    // Through the change of vertex data percent point model from large to small, small tadpole shape flying lines are generated
    geometry.attributes.percent = percentAttribue;
    // Batch computes all vertex color data
    const colorArr = [];
    for (let i = 0; i < pointsArr.length; i++) {
        const color1 = new Color(0xec8f43); // Trace line color cyan
        const color2 = new Color(0x43ec8f); // yellow
        const color = color1.lerp(color2, i / pointsArr.length);
        colorArr.push(color.r, color.g, color.b);
    }
    //  Set geometry vertex color data
    geometry.attributes.color = new BufferAttribute( new Float32Array(colorArr), 3);
    const size = 0.2;
    // point model render geometry per vertex
    const material = new PointsMaterial({
        size, //  point size
        // vertexColors: VertexColors, // Render with vertex color
        transparent: true,
        depthWrite: false,
    });
    // Modify the shader source code of the point material (note: the details of different versions may be slightly different, but the overall idea is the same)
    material.onBeforeCompile = function (shader) {
        //  Declare an attribute variable in the vertex shader: percentage
        shader.vertexShader = shader.vertexShader.replace(
            "void main() {",
            [
                "attribute float percent;", // Vertex size percentage variable, controls point rendering size
                "void main() {",
            ].join("\n") // .join() Combine array elements into strings
        );
        // Adjust point rendering size calculation method
        shader.vertexShader = shader.vertexShader.replace(
            "gl_PointSize = size;",
            ["gl_PointSize = percent * size;"].join("\n")
        );
    };
    const FlyLine = new Points(geometry, material);
    material.color = new Color(color)
    FlyLine.name = "FlyLine";

    return FlyLine;
}


/** Enter the latitude and longitude coordinates of any two points on the planet, and use the function flyArc to draw a flying line arc trajectory
 * lon1,lat1: Longitude and latitude coordinates of the starting point of the trajectory line
 * lon2,lat2: The latitude and longitude coordinates of the end point of the trajectory line
 */
function flyArc(radius, lon1, lat1, lon2, lat2, options) {
    const sphereCoord1 = lon2xyz(radius, lon1, lat1); // Latitude and longitude coordinates to spherical coordinates
    //  startSphereCoord: spherical coordinates of the starting point of the trajectory line
    const startSphereCoord = new Vector3(sphereCoord1.x, sphereCoord1.y, sphereCoord1.z);
    const sphereCoord2 = lon2xyz(radius, lon2, lat2);
    //  startSphereCoord: Spherical coordinates of the end point of the trajectory line
    const endSphereCoord = new Vector3(sphereCoord2.x, sphereCoord2.y, sphereCoord2.z);

    // Calculate the starting point, ending point and rotation quaternion required to draw an arc symmetrical about the y-axis
    const startEndQua = _3Dto2D(startSphereCoord, endSphereCoord)
    // Call arcXOY function to draw an arc flying line trajectory
    const arcline = arcXOY(radius, startEndQua.startPoint, startEndQua.endPoint, options);
    arcline.quaternion.multiply(startEndQua.quaternion)
    return arcline;
}
/*
* Rotate the start and end points of any two flying lines on the 3D sphere around the center of the sphere to the XOY plane,
* While maintaining symmetry about the y-axis, draw with the new starting point and new ending point obtained by the rotation
* An arc, and finally reverse the drawn arc to the original starting point and ending point.
*/
function _3Dto2D(startSphere, endSphere) {
    /* Compute the quaternion of the first rotation: representing how to rotate from one plane to another */
    const origin = new Vector3(0, 0, 0); // Center coordinates
    const startDir = startSphere.clone().sub(origin); // The starting point of the flying line and the center of the ball form the direction vector
    const endDir = endSphere.clone().sub(origin); // The end point of the flying line and the center of the ball form the direction vector
    // dir1 and dir2 form a triangle, and .cross() calculates the normal normal of the triangle.
    const normal = startDir.clone().cross(endDir).normalize();
    const xoyNormal = new Vector3(0, 0, 1); // Normal to the XOY plane
    // .setFromUnitVectors() calculates the quaternion required to rotate from the normal vector to the xoyNormal vector
    // quaternion represents the quaternion required to rotate the spherical flying line to the XOY plane
    const quaternion3D_XOY = new Quaternion().setFromUnitVectors(normal, xoyNormal); 
    /*The first rotation: the starting point and end point of the flying line are rotated for the first time from the 3D space to the XOY plane*/
    const startSphereXOY = startSphere.clone().applyQuaternion(quaternion3D_XOY);
    const endSphereXOY = endSphere.clone().applyQuaternion(quaternion3D_XOY);

    /* Calculate the quaternion for the second rotation */
    // middleV3： Midpoint of startSphereXOY and endSphereXOY
    const middleV3 = startSphereXOY.clone().add(endSphereXOY).multiplyScalar(0.5);
    const midDir = middleV3.clone().sub(origin).normalize(); //  The vector midDir before rotation, the direction vector formed by the midpoint middleV3 and the center of the sphere
    const yDir = new Vector3(0, 1, 0); // The rotated vector yDir, the y-axis
    // .setFromUnitVectors() calculates the quaternion required to rotate from the midDir vector to the yDir vector
    // quaternion2 represents the quaternion required to make the first rotation to the start and end points of the XOY plane symmetrical about the y-axis
    const quaternionXOY_Y = new Quaternion().setFromUnitVectors(midDir, yDir);

    /* The second rotation: rotate the point rotated to the XOY plane again to achieve symmetry about the Y axis */
    const startSpherXOY_Y = startSphereXOY.clone().applyQuaternion(quaternionXOY_Y);
    const endSphereXOY_Y = endSphereXOY.clone().applyQuaternion(quaternionXOY_Y);

    /** A quaternion represents a rotation process
     * The .invert() method represents the inverse of the quaternion, which is simply to reverse the rotation process
     * Perform .invert() to invert the twice rotated quaternion, and then perform .multiply() to multiply
     * The new version.invert() corresponds to the old version.invert()
     */
    const quaternionInverse = quaternion3D_XOY.clone().invert().multiply(quaternionXOY_Y.clone().invert())
    return {
        //  Returns the inverse quaternion of the twice rotated quaternion
        quaternion: quaternionInverse,
        //  The coordinates of the start and end points of the arc symmetrical about the y-axis on the XOY plane after the range is rotated twice
        startPoint: startSpherXOY_Y,
        endPoint: endSphereXOY_Y,
    }
}

/** Through the function arcXOY(), a circular arc curve that is symmetrical about the y-axis can be drawn on the XOY plane
 * startPoint, endPoint：Indicates the coordinate values ​​of the starting point and ending point of the arc curve. The starting point and ending point are symmetrical about the y-axis
 * At the same time, draw a flying line on the basis of the arc trajectory */
function arcXOY(radius, startPoint, endPoint, options) {
    // Calculate the midpoint of two points
    const middleV3 = new Vector3().addVectors(startPoint, endPoint).multiplyScalar(0.5);
    // The direction of the chord vertical line dir (the vector formed by the midpoint of the chord and the center of the circle)
    const dir = middleV3.clone().normalize()
    //  Calculate the radian value of the angle formed by the starting point, ending point and the center of the spherical flying line
    const earthRadianAngle = radianAOB(startPoint, endPoint, new Vector3(0, 0, 0))
    /* Set the coordinates of the middle point of the arc of the flying line track
    Radian value * radius * 0.2 Indicates the distance from the top of the flying line trajectory arc to the earth's sphere
    The farther the start and end points are together, the higher the distance from the top of the arc to the sphere.*/
    const arcTopCoord = dir.multiplyScalar(radius + earthRadianAngle * radius * 0.2) // Height of the yellow flying line
    // Find the center of the circumcircle of the three points (the coordinates of the center of the arc trajectory of the flying line)
    const flyArcCenter = threePointCenter(startPoint, endPoint, arcTopCoord)
    //  Flying line arc track radius flyArcR
    const flyArcR = Math.abs(flyArcCenter.y - arcTopCoord.y);
    /* The origin of the coordinates and the starting point of the flying line form the radian value of the angle between the straight line and the negative half-axis of the y-axis
    The parameters are: the starting point of the flying line arc, a point on the negative half-axis of the y-axis, and the center of the flying line arc */
    const flyRadianAngle = radianAOB(startPoint, new Vector3(0, -1, 0), flyArcCenter);
    const startAngle = -Math.PI / 2 + flyRadianAngle; // Flying line arc start angle
    const endAngle = Math.PI - startAngle; // Flying line arc end angle
    // Call the drawing function of the arc model
    const arcline = circleLine(flyArcCenter.x, flyArcCenter.y, flyArcR, startAngle, endAngle, options.color)
    // const arcline = new  Group(); // Do not draw the trajectory line, use Group to replace circleLine()
    arcline.center = flyArcCenter; // The flying line arc has a custom attribute representing the center of the flying line arc
    arcline.topCoord = arcTopCoord; // The flying line arc has a custom attribute indicating that the middle of the flying line arc is the top coordinate

    // const flyAngle = Math.PI/ 10; // Flying lead arc fixed radian
    const flyAngle = (endAngle - startAngle) / 7; // The arc of the flying line arc is related to the arc of the track line
    // Draw a flying line with the center of the circle as the origin of coordinates
    const flyLine = createFlyLine(flyArcR, startAngle, startAngle + flyAngle, options.flyLineColor);
    flyLine.position.y = flyArcCenter.y; //  Translate flyline arc and flyline track arc coincident
    // The flying line segment flyLine, as the arcLine sub-object of the flying line trajectory, inherits transformations such as translation and rotation of the flying line trajectory.
    arcline.add(flyLine);
    // Flying line segment motion range startAngle~flyEndAngle
    flyLine.flyEndAngle = endAngle - startAngle - flyAngle;
    flyLine.startAngle = startAngle;
    // arcline.flyEndAngle： The current angular position of the flying line segment, a random value is set here for demonstration
    flyLine.AngleZ = arcline.flyEndAngle * Math.random();
    // flyLine.rotation.z = arcline.AngleZ;
    // arcline.flyLine points to the flying line segment, easy to animate is to access the flying line segment
    arcline.userData['flyLine'] = flyLine;

    return arcline
}

/* Calculate the radian value of the angle formed by two points on the sphere and the center of the sphere
Parameters point1, point2: Indicates the coordinates of two points on the earth's sphere Vector3
Calculate the radian value of the included angle of AOB formed by two points A, B and vertex O*/
function radianAOB(A, B, O) {
    // dir1, dir2: the direction vector formed by two points on the sphere and the center of the sphere
    const dir1 = A.clone().sub(O).normalize();
    const dir2 = B.clone().sub(O).normalize();
    // product.dot() calculates the cosinus of the included angle
    const cosAngle = dir1.clone().dot(dir2);
    const radianAngle = Math.acos(cosAngle); // The cosine value is the radian value of the included angle, and the included angle range can be calculated by the cosine value from 0 to 180 degrees
    return radianAngle
}
/* Draw an arc curve model Line
The meanings of the 5 parameters: (abscissa of the center of the circle, ordinate of the center of the circle, radius of the flying line arc track, start angle, end angle) */
function circleLine(x, y, r, startAngle, endAngle, color) {
    const geometry = new BufferGeometry(); // Declare a geometry object Geometry
    // ArcCurve creates arc curves
    const arc = new ArcCurve(x, y, r, startAngle, endAngle, false);
    // getSpacedPoints is a method of the base class Curve that returns a vector2 object as an array of elements
    const points = arc.getSpacedPoints(80); // Number of segments 50, returns 51 vertices
    geometry.setFromPoints(points); // The setFromPoints method extracts data from points and changes the vertex attribute vertices of the geometry
    const material = new LineBasicMaterial({
        color: color || 0xd18547,
    }); // Line material
    const line = new Line(geometry, material); // line model object
    return line;
}
// Find the center of the circumcircle of the three points, p1, p2, p3 represent the coordinates of the three points Vector3.
function threePointCenter(p1, p2, p3) {
    const L1 = p1.lengthSq(); // The square of the distance from p1 to the origin of the coordinates
    const L2 = p2.lengthSq();
    const L3 = p3.lengthSq();
    const x1 = p1.x,
        y1 = p1.y,
        x2 = p2.x,
        y2 = p2.y,
        x3 = p3.x,
        y3 = p3.y;
    const S = x1 * y2 + x2 * y3 + x3 * y1 - x1 * y3 - x2 * y1 - x3 * y2;
    const x = (L2 * y3 + L1 * y2 + L3 * y1 - L2 * y1 - L3 * y2 - L1 * y3) / S / 2;
    const y = (L3 * x2 + L2 * x1 + L1 * x3 - L1 * x2 - L2 * x3 - L3 * x1) / S / 2;
    // The coordinates of the center of the circumcircle of three points
    const center = new Vector3(x, y, 0);
    return center
}

export {
    arcXOY,
    flyArc
}