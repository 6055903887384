import {
  Texture,
  BufferGeometry,
  Color,
  Group,
  Points,
  PointsMaterial,
  ShaderMaterial,
  MeshBasicMaterial,
  SphereGeometry,
  Mesh,
  SpriteMaterial,
  Sprite,
  Vector3,
  BufferAttribute,
  DoubleSide,
  NormalBlending,
  BackSide,
} from "three/src/Three";
import planetFragmentShader from "../Shaders/Earth/fragment";
import planetVertexShader from "../Shaders/Earth/vertex";
import gsap from "gsap";
import { flyArc } from "utils/arc";
import aeroSphereFragmentShader from "../Shaders/AeroSphere/fragment";
import aeroSphereVertexShader from "../Shaders/AeroSphere/vertex";

export type punctuation = {
  circleColor: number;
  lightColumn: {
    startColor: number; // Starting Color
    endColor: number; // End Color
  };
};

type options = {
  data: {
    startArray: {
      name: string;
      E: number; // Longitude
      N: number; // Dimension
    };
    endArray: {
      name: string;
      E: number; // Longitude
      N: number; // Dimension
    }[];
  }[];
  dom: HTMLElement;
  textures: Record<string, Texture>;
  planet: {
    radius: number;
    rotateSpeed: number;
    isRotation: boolean;
  };
  moon: {
    show: boolean;
    rotateSpeed: number;
    size: number;
  };
  punctuation: punctuation;
  flyLine: {
    color: number;
    speed: number;
    flyLineColor: number;
  };
};

type uniforms = {
  glowColor: { value: Color };
  scale: { type: string; value: number };
  bias: { type: string; value: number };
  power: { type: string; value: number };
  time: { type: string; value: any };
  isHover: { value: boolean };
  map: { value: Texture | null };
};

export default class Planet {
  public group: Group;

  public planet: Mesh<SphereGeometry, ShaderMaterial>;
  public planetGroup: Group;

  public atmosphere: Mesh<SphereGeometry, ShaderMaterial>;
  public atmosphereGroup: Group;

  public clouds: Mesh<SphereGeometry, MeshBasicMaterial>;
  public cloudsGroup: Group;

  public moon: Mesh<SphereGeometry, MeshBasicMaterial>;
  public moonGroup: Group;

  public galaxy: Mesh<SphereGeometry, MeshBasicMaterial>;
  public galaxyGroup: Group;

  public starPoints: Points<BufferGeometry, PointsMaterial>;
  public star: BufferGeometry;

  public options: options;
  public uniforms: uniforms;
  public timeValue: number;

  public punctuationMaterial: MeshBasicMaterial;
  public markupPoint: Group;

  public isRotation: boolean;
  public flyLineArcGroup: Group;

  constructor(options: options) {
    this.options = options;

    this.group = new Group();
    this.group.name = "group";
    this.group.scale.set(0, 0, 0);

    this.planetGroup = new Group();
    this.group.add(this.planetGroup);
    this.planetGroup.name = "PlanetGroup";

    this.atmosphereGroup = new Group();
    this.group.add(this.atmosphereGroup);
    this.atmosphereGroup.name = "PlanetAtmosphere"

    this.cloudsGroup = new Group();
    this.group.add(this.cloudsGroup);
    this.cloudsGroup.name = "CloudsGroup";

    this.galaxyGroup = new Group();
    this.group.add(this.galaxyGroup);
    this.galaxyGroup.name = "GalaxyGroup";

    this.moonGroup = new Group();
    this.group.add(this.moonGroup);
    this.moonGroup.name = "MoonGroup";

    // Planet's rotation
    this.isRotation = this.options.planet.isRotation;

    // Sweep animation shader
    this.timeValue = 1000;

    this.uniforms = {
      glowColor: {
        value: new Color(0x000000),
      },
      scale: {
        type: "f",
        value: -1,
      },
      bias: {
        type: "f",
        value: 1,
      },
      power: {
        type: "f",
        value: 4.0,
      },
      time: {
        type: "f",
        value: this.timeValue,
      },
      isHover: {
        value: false,
      },
      map: {
        value: null,
      },
    };
  }

  async init(): Promise<void> {
    return new Promise(async (resolve) => {
      this.createGalaxyCluster();
      this.createStars();

      this.createPlanet();
      this.createPlanetClouds();
      this.createPlanetGlow();
      this.createPlanetAtmosphere();
      
      //   this.createFlyLine();
      this.createMoon();

      this.show();
      resolve();
    });
  }

  createGalaxyCluster() {
    const galaxy_geometry = new SphereGeometry(this.options.planet.radius * 10000, 60, 60);
    const galaxy_material = new MeshBasicMaterial({ side: BackSide });
    galaxy_material.map = this.options.textures.skybox;

    galaxy_material.needsUpdate = true;

    this.galaxy = new Mesh(galaxy_geometry, galaxy_material);
    this.galaxy.name = "galaxy";
    this.galaxyGroup.add(this.galaxy);
  }

  createStars() {
    const stars = [];

    for (let i = 0; i < 1000; i++) {
      const star = new Vector3();
      star.x = 10000 * Math.random() - 5000;
      star.y = 10000 * Math.random() - 5000;
      star.z = 10000 * Math.random() - 5000;
      stars.push(star.x, star.y, star.z);
    }

    this.star = new BufferGeometry();
    this.star.setAttribute("position", new BufferAttribute(new Float32Array(stars), 3));

    const stars_material = new PointsMaterial({
      size: 1,
      sizeAttenuation: true,
      color: 0xffffff,
      transparent: true,
      opacity: 0.6,
      map: this.options.textures.gradient,
    });

    this.starPoints = new Points(this.star, stars_material);
    this.starPoints.name = "Stars";
    this.starPoints.scale.set(1, 1, 1);
    this.group.add(this.starPoints);
  }

  createPlanet() {
    const planet_geometry = new SphereGeometry(this.options.planet.radius, 60, 60);
    this.uniforms.map.value = this.options.textures.surface;
    // change to MeshPhongMaterial / make lights
    const planet_material = new ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: planetVertexShader,
      fragmentShader: planetFragmentShader,
    });

    planet_material.needsUpdate = true;

    this.planet = new Mesh(planet_geometry, planet_material);
    this.planet.name = "planet";
    this.planetGroup.add(this.planet);
  }

  createPlanetClouds() {
    const clouds_geometry = new SphereGeometry(this.options.planet.radius + 0.03, 60, 60 );
    const clouds_material = new MeshBasicMaterial({ side: DoubleSide, transparent: true});
    clouds_material.map = this.options.textures.clouds;

    clouds_material.needsUpdate = true;
    
    this.clouds = new Mesh(clouds_geometry, clouds_material);
    this.clouds.name = "clouds";
    this.cloudsGroup.add(this.clouds);
  }

  createPlanetAtmosphere() {
    const atmosphere_geometry = new SphereGeometry(this.options.planet.radius + 0.2, 60, 60);
    const atmosphere_texture = {
      uniforms: {
        coeficient: {
          type: "f",
          value: 0.8,
        },
        power: {
          type: "f",
          value: 5,
        },
        glowColor: {
          type: "c",
          value: new Color(0xc5ddf1),
        },
      },
      vertexShader: aeroSphereVertexShader,
      fragmentShader: aeroSphereFragmentShader,
    };
    // sphere glow atmosphere
    const atmosphere_material = new ShaderMaterial({
      uniforms: atmosphere_texture.uniforms,
      vertexShader: atmosphere_texture.vertexShader,
      fragmentShader: atmosphere_texture.fragmentShader,
      blending: NormalBlending,
      transparent: true,
      depthWrite: false,
    });
    
    const atmopshere = new Mesh(atmosphere_geometry, atmosphere_material);
    this.atmosphereGroup.add(atmopshere);
  }

  createPlanetGlow() {
    const glow_radius = this.options.planet.radius; // Radius
    const glow_texture = this.options.textures.glow; // Texture

    const glow_material = new SpriteMaterial({
      map: glow_texture,
      color: 0x33ccff,
      transparent: true,
      opacity: 1,
      depthWrite: true,
    });

    const glow_geometry = new Sprite(glow_material);
    glow_geometry.scale.set(glow_radius * 3.0, glow_radius * 3.0, 1);
    this.planetGroup.add(glow_geometry);
  }

  createFlyLine() {
    this.flyLineArcGroup = new Group();
    this.flyLineArcGroup.userData["flyLineArray"] = [];
    this.planetGroup.add(this.flyLineArcGroup);

    this.options.data.forEach((cities) => {
      cities.endArray.forEach((item) => {
        // Call the function flyArc to draw the arc trajectory of the flying line between any two points on the sphere
        const arcline = flyArc(
          this.options.planet.radius,
          cities.startArray.E,
          cities.startArray.N,
          item.E,
          item.N,
          this.options.flyLine
        );
        this.flyLineArcGroup.add(arcline); //  The flying line is inserted into the flyArcGroup
        this.flyLineArcGroup.userData["flyLineArray"].push(
          arcline.userData["flyLine"]
        );
      });
    });
  }

  createMoon() {
    const moon_geometry = new SphereGeometry(this.options.moon.size, 64, 64);
    const moon_texture = new MeshBasicMaterial({ map: this.options.textures.moon })
    this.moon = new Mesh(moon_geometry, moon_texture);
    this.moon.name = "Moon";

    this.moon.position.set(this.options.planet.radius + 15, 0, 0);
    this.moonGroup.add(this.moon); 
  }

  show() {
    gsap.to(this.group.scale, {
      x: 1,
      y: 1,
      z: 1,
      duration: 6,
      ease: "Quadratic",
    });
  }

  render() {
    // this.flyLineArcGroup?.userData["flyLineArray"]?.forEach(
    //   (fly: { rotation: { z: number }; flyEndAngle: number }) => {
    //     fly.rotation.z += this.options.flyLine.speed;
    //     if (fly.rotation.z >= fly.flyEndAngle) fly.rotation.z = 0;
    //   }
    // );

    if (this.isRotation) {
      this.planetGroup.rotation.y += this.options.planet.rotateSpeed;
	    this.cloudsGroup.rotation.y += this.options.planet.rotateSpeed * 0.8;
      this.galaxyGroup.rotation.z -= 0.00001;
      this.galaxyGroup.rotation.y -= 0.00001;
      this.moonGroup.rotation.y += this.options.moon.rotateSpeed;
    }

    this.uniforms.time.value = this.uniforms.time.value < -this.timeValue ? this.timeValue : this.uniforms.time.value - 1;
  }
}
