import { CatmullRomCurve3, DoubleSide, Group, Mesh, MeshBasicMaterial, BufferGeometry, Texture, TubeGeometry, Vector3 } from "three";
import { punctuation } from "classes/Gameplay/Components/Planet";
/**
 * Latitude and longitude coordinates to spherical coordinates
 * @param {radius} R  
 * @param {longitude(angleValue)} longitude 
 * @param {dimension(angleValue)} latitude
 */
export const lon2xyz = (R:number, longitude:number, latitude:number): Vector3 => {
  let lon = longitude * Math.PI / 180; //  Radian value
  const lat = latitude * Math.PI / 180; // Radian value
  lon = -lon; // The z coordinate axis of the js coordinate system corresponds to -90 degrees longitude, not 90 degrees

  // Calculation formula for converting latitude and longitude coordinates to spherical coordinates
  const x = R * Math.cos(lat) * Math.cos(lon);
  const y = R * Math.sin(lat);
  const z = R * Math.cos(lat) * Math.sin(lon);
  // return spherical coordinates
  return new Vector3(x, y, z);
}

// Create a wave circle
export const createWaveMesh = (options: { radius: number, lon: number, lat: number, textures: { aperture: any; } }) => {
  const geometry = new BufferGeometry(); // Default on XOY plane
  const texture = options.textures.aperture;

  const material = new MeshBasicMaterial({
    color: 0xe99f68,
    map: texture,
    transparent: true, // Use png texture with transparent background, pay attention to open transparent calculation
    opacity: 1.0,
    depthWrite: false, // Disable writing of depth buffer data
  });
  const mesh = new Mesh(geometry, material);
  // Latitude and longitude to spherical coordinates
  const coord = lon2xyz(options.radius * 1.001, options.lon, options.lat);
  const size = options.radius * 0.12; // The size of the rectangular plane Mesh
  mesh.scale.set(size, size, size); // set mesh size
  mesh.userData['size'] = size; // An attribute from the top, indicating the static size of the mesh
  mesh.userData['scale'] = Math.random() * 1.0; // Custom attribute ._s indicates the magnification of the mesh based on the original size, and the aperture changes between 1 and 2 times based on the original mesh.size
  mesh.position.set(coord.x, coord.y, coord.z);
  const coordVec3 = new Vector3(coord.x, coord.y, coord.z).normalize();
  const meshNormal = new Vector3(0, 0, 1);
  mesh.quaternion.setFromUnitVectors(meshNormal, coordVec3);
  return mesh;
}

// Create a column
export const createLightPillar = (options: { radius: number, lon: number, lat: number, index: number, textures: Record<string, Texture>, punctuation: punctuation }) => {
  const height = options.radius * 0.3;
  const geometry = new BufferGeometry();
  geometry.rotateX(Math.PI / 2);
  geometry.translate(0, 0, height / 2);
  const material = new MeshBasicMaterial({
    map: options.textures.light_column,
    color:
      options.index === 0
        ? options.punctuation.lightColumn.startColor
        : options.punctuation.lightColumn.endColor,
    transparent: true,
    side: DoubleSide,
    depthWrite: false, //  Does it have any effect on the depth buffer
  });
  const mesh = new Mesh(geometry, material);
  const group = new Group();
  //  The two beams are crossed and superimposed
  group.add(mesh, mesh.clone().rotateZ(Math.PI / 2)); // The geometry is rotated around the x axis, so the mesh rotation axis becomes z
  //  Latitude and longitude to spherical coordinates
  const SphereCoord = lon2xyz(options.radius, options.lon, options.lat); // SphereCoord spherical coordinates
  group.position.set(SphereCoord.x, SphereCoord.y, SphereCoord.z); // set mesh location
  const coordVec3 = new Vector3(
    SphereCoord.x,
    SphereCoord.y,
    SphereCoord.z
  ).normalize();
  const meshNormal = new Vector3(0, 0, 1);
  group.quaternion.setFromUnitVectors(meshNormal, coordVec3);
  return group;
}

// Light column base rectangular plane
export const createPointMesh = (options: {
  radius: number, lon: number,
  lat: number, material: MeshBasicMaterial
}) => {

  const geometry = new BufferGeometry(); //  Default on XOY plane
  const mesh = new Mesh(geometry, options.material);
  // Latitude and longitude to spherical coordinates
  const coord = lon2xyz(options.radius * 1.001, options.lon, options.lat);
  const size = options.radius * 0.1; // The size of the rectangular plane Mesh
  mesh.scale.set(size, size, size); // set mesh size

  // set mesh location
  mesh.position.set(coord.x, coord.y, coord.z);
  const coordVec3 = new Vector3(coord.x, coord.y, coord.z).normalize();
  const meshNormal = new Vector3(0, 0, 1);
  mesh.quaternion.setFromUnitVectors(meshNormal, coordVec3);
  return mesh;

}

//  get points
export const getCirclePoints = (option: { number: any; radius: any; closed: any; }) => {
  const list = [];
  for (
    let j = 0;
    j < 2 * Math.PI - 0.1;
    j += (2 * Math.PI) / (option.number || 100)
  ) {
    list.push([
      parseFloat((Math.cos(j) * (option.radius || 10)).toFixed(2)),
      0,
      parseFloat((Math.sin(j) * (option.radius || 10)).toFixed(2)),
    ]);
  }
  if (option.closed) list.push(list[0]);
  return list;
}

//  Create lines

/**
 * Create dynamic lines
 */
export const createAnimateLine = (option: any) => {
  //  Curves consisting of multiple arrays of points Typically used for roads
  const l: Vector3[] | undefined = [];
  option.pointList.forEach((e:any) =>
    l.push(new Vector3(e[0], e[1], e[2]))
  );
  const curve = new CatmullRomCurve3(l); //  curved path

  // pipe body
  const tubeGeometry = new TubeGeometry(
    curve,
    option.number || 50,
    option.radius || 0.1,
    option.radialSegments
  );
  return new Mesh(tubeGeometry, option.material);
}