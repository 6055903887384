const aeroSphereVertexShader=`
varying vec3 vVertexWorldPosition;
varying vec3 vVertexNormal;
varying vec4 vFragColor;

void main() {
    vVertexNormal	= normalize(normalMatrix * normal); // Convert the normal to the view coordinate system
    vVertexWorldPosition	= (modelMatrix * vec4(position, 1.0)).xyz; // Convert vertices to world coordinate system
    // set gl_Position
    gl_Position	= projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`

export default aeroSphereVertexShader;