import { initializeApp } from "firebase/app";
import { getDatabase, ref as dbRef, set } from "firebase/database";
import { getStorage, listAll, ref as sRef, getDownloadURL, StorageReference } from "firebase/storage";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyACoyv5h5YT7rVR-L32b1izcPHvgHben_w",
  authDomain: "calypso-fbm-app.firebaseapp.com",
  projectId: "calypso-fbm-app",
  storageBucket: "calypso-fbm-app.appspot.com",
  databaseURL: "https://calypso-fbm-app-default-rtdb.europe-west1.firebasedatabase.app/",
  messagingSenderId: "1062301615975",
  appId: "1:1062301615975:web:e09334551b49d2ca3711c2",
  measurementId: "G-9L08KQPKZ0",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getDatabase(app);
export const storage = getStorage(app);

const listRef = sRef(storage, "/resources/ThreeJS/Planets/Earth");

export function writeUserData(userId: string, name: string, email: string, imageUrl: string) {
  set(dbRef(db, 'users/' + userId), {
    username: name,
    email: email,
    profile_picture : imageUrl
  });
}

export function createAccount(email: string, password: string) {
  createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    // const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode + " : " + errorMessage);
  });
}

export function signInAccount(email: string, password: string) {
  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    // const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode + " : " + errorMessage);
  });
}

export function signOutAccount() {
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    console.log(error)
  });
}

export function FirebaseAuth(): boolean {
  const [online, setOnline] = useState(false);
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setOnline(true);
      } else {
        setOnline(false);
      }
    });
  })
  return online;
}

export const textureLibrary: {name: string, url: string}[] = [];

async function listRefs() {
  let ref: StorageReference[] = [];
  await listAll(listRef)
  .then((files) => { ref = files.items})
  .catch((error) => console.log(error))
  return ref;
}

export async function getMeta() {
  const storageRefs = await listRefs();
  storageRefs.map((storageRef) => {
    getDownloadURL(sRef(storage, storageRef.fullPath)).then((item) => {
      textureLibrary.push({name: storageRef.name, url: item})
    })
    return null;
  })
}
export default app;
