const Data = [{
    startArray: {
      name: 'Hangzhou',
      N: 30.246026,
      E: 120.210792,
    },
    endArray: [{
        name: 'Bangkok',
        N: 22,
        E: 100.49074172973633, 
      },
      {
        name: 'Australia',
        N: -23.68477416688374,
        E: 133.857421875,
      },
  
      {
        name: 'Xinjiang',
        N: 41.748,
        E: 84.9023,
      },
  
      {
        name: 'Tehran',
        N: 35,
        E: 51,
      },
      {
        name: 'U.S.A.',
        N: 34.125447565116126,
        E: 241.7431640625,
      },
      {
        name: 'U.K.',
        N: 51.508742458803326,
        E: 359.82421875,
      },
      {
        name: 'Brazil',
        N:  -9.96885060854611,
        E: 668.1445312499999,
      },
    ]
  },
  {
    startArray: {
      name: 'Beijing',
      N: 39.89491,
      E: 116.322056,
    },
    endArray: [{
        name: 'Tibet',
        N: 29.660361,
        E: 91.132212 
      },
      {
        name: 'Guangxi',
        N: 22.830824,
        E: 108.30616
      },
  
      {
        name: 'Jiangxi',
        N: 28.676493,
        E: 115.892151
      },
  
      {
        name: 'Guiyang',
        N: 26.647661,
        E: 106.630153
      }
    ]
  }
]

export default Data;