import { MeshBasicMaterial, PerspectiveCamera, Scene, ShaderMaterial, WebGLRenderer } from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { IWorld } from "../Interfaces/IWorld";
import { ResourceLoader } from "../ResourceLoader";

import Sizes from "utils/Sizes";
import Data from "../MockData"

import { ThreeCanvas } from "../Canvas";
import Planet from "../Planet";

export default class Galaxy {
  public mainDOM: ThreeCanvas;
  public scene: Scene;
  public camera: PerspectiveCamera;
  public renderer: WebGLRenderer;
  public controls: OrbitControls;
  public sizes: Sizes;
  public material: ShaderMaterial | MeshBasicMaterial;
  public resources: ResourceLoader;
  public option: IWorld;
  public planet: Planet;

  constructor(option: IWorld) {
    this.option = option;
    this.mainDOM = new ThreeCanvas(option.mainDOM);
    this.scene = this.mainDOM.scene;
    this.renderer = this.mainDOM.renderer;
    this.controls = this.mainDOM.controls;
    this.camera = this.mainDOM.camera;

    this.sizes = new Sizes({ dom: option.mainDOM })

    this.sizes.$on('resize', () => {
      this.renderer.setSize(Number(this.sizes.viewport.width), Number(this.sizes.viewport.height));
      this.camera.aspect = Number(this.sizes.viewport.width) / Number(this.sizes.viewport.height);
      this.camera.updateProjectionMatrix();
    });

    this.resources = new ResourceLoader(async () => {
      await this.createEarth();
      this.render();
    });
  }
  async createEarth() {
    this.planet = new Planet({
      data: Data,
      dom: this.option.mainDOM,
      textures: this.resources.textures,
      planet: {
        radius: 10,
        rotateSpeed: -0.0002,
        isRotation: true
      },
      moon: {
        show: true,
        rotateSpeed: 0.0001,
        size: 2
      },
      punctuation: {
        circleColor: 0x3892ff,
        lightColumn: {
          startColor: 0xe4007f,
          endColor: 0xffffff,
        },
      },
      flyLine: {
        color: 0x003366,
        flyLineColor: 0x33cc33,
        speed: 0.001,
      }
    })

    this.scene.add(this.planet.group)

    await this.planet.init()
  }

  public render() {
    requestAnimationFrame(this.render.bind(this));
    this.renderer.render(this.scene, this.camera);
    this.controls && this.controls.update();
    this.planet && this.planet.render();
  }
}
