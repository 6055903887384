import { createSlice } from '@reduxjs/toolkit';

export const ServersModalSlice = createSlice({
    name: 'serversModal',
    initialState: {
      visible: false,
    },
    reducers: {
      enableServersModal: (state) => {
        state.visible = true;
      },
      disableServersModal: (state) => {
        state.visible = false;
      },
    },
})

export const { enableServersModal, disableServersModal } = ServersModalSlice.actions

export const serversModalVisibleStatus = (state: { serversModal: { visible: boolean; }; }) => state.serversModal.visible;
  
export default ServersModalSlice.reducer