import {
  FirebaseAuth,
  createAccount,
  signInAccount,
  signOutAccount,
} from "classes/Firebase";
import React, { useState } from "react";
import { PersonCircle } from "react-bootstrap-icons";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from 'react-redux';
import { enableAuthModal, disableAuthModal, authModalVisibleStatus } from "./slice";
// import { enableServersModal } from "../ServersModal/slice"
import "./styles.css";

const AuthModal: React.FC = () => {
  const modalVisible = useSelector(authModalVisibleStatus);
  const userLogged: boolean = FirebaseAuth();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e:any) => {
      const { name, value } = e.target
      setDetails((prev) => {
        return { ...prev, [name]: value };
      });
  };

  const handleSubmit = (e:any) => {
    if (e.target.name === "login") {
      signInAccount(details.email, details.password);
      dispatch(disableAuthModal());
      // dispatch(enableServersModal());
    }
    else if (e.target.name === "register") {
      createAccount(details.email, details.password);
    }
    else if (e.target.name === "logout") {
      signOutAccount();
    }
  }

  return (
    <>
      <button title="Modal" type="button" className="ModalButtonContainer" onClick={() => dispatch(enableAuthModal())}>
        <PersonCircle size={24} className="ModalButton" />
      </button>
      <Modal
        show={modalVisible}
        onHide={() => dispatch(disableAuthModal())}
        contentClassName="AuthModal"
        centered
      >
        <Modal.Body>
          <div className="imgcontainer">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/calypso-fbm-app.appspot.com/o/M.svg?alt=media&token=86ec9c35-11db-4b94-9bcb-677f60fdcfaa"
              alt="loginPic"
              className="loginPic"
            />
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="container">
              <div className="inputFields" hidden={userLogged}>
                <label>
                  <b>Email</b>
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Email Address"
                  value={details.email}
                  onChange={(e) => handleChange(e)}
                />
                <label>
                  <b>Password</b>
                </label>
                <input
                  type="password"
                  name="password"
                  placeholder="Enter Password"
                  value={details.password}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
          </form>
          <button
            className="Pbutton login"
            onClick={(e) => handleSubmit(e)}
            name="login"
            hidden={userLogged}
            disabled={false}
          >
            Login
          </button>
          <button
            className="Pbutton register"
            onClick={(e) => handleSubmit(e)}
            name="register"
            hidden={userLogged}
            disabled={true}
          >
            Register
          </button>
          <button
            className="Pbutton signout"
            onClick={(e) => handleSubmit(e)}
            name="logout"
            hidden={!userLogged}
            disabled={false}
          >
            Logout
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AuthModal;
