import React, { useEffect, useRef } from "react";
import { Provider } from 'react-redux';
import store from "store/index";

import './styles.css'
import AuthModal from "components/AuthModal";
import Galaxy from "classes/Gameplay/Components/Galaxy";
// import ServersModal from "components/ServersModal"

const Home: React.FC = () => {
  const htmlElement: HTMLElement = document.getElementById('root') as HTMLElement;
  const rendered = useRef(false);

  useEffect(() => {
    if(!rendered.current) {
      new Galaxy({mainDOM: htmlElement});
    }
    rendered.current = true;
  })

  return (
    <Provider store={store}>
        {/* <ServersModal/> */}
        <AuthModal/>
        {/* <Gameplay/> */}
    </Provider>
  );
};

export default Home;